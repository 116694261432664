import React, { Component } from 'react'
import { navigate } from "gatsby"
import Img from 'gatsby-image'
import {FiSend} from "react-icons/fi"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../style/contact.module.css"

//Use navigate function to have a thank you page

class ContactPage extends Component {

    state = {
        name: '',
        email: '',
        subjectTitle: '',
        message: ''
        //Or its one object?
    }
    
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    // handleSubmit = (e) => {
    //     navigate("/form-submitted/")
    // }

    render() {
        return(        
            <Layout>
                <SEO title='Contact Me' description='Message page to contact Paul Jang'/>
                <h1>Contact Me</h1>
                <hr className={styles.titleDivider}/>
                <p>Feel free to reach out if you'd like some more information on my work, to give feedback, or just to chat!</p>

                <form name="contact" method="POST" action="/sentmsg" netlify-honeypot="bot-field" data-netlify="true">
                    <p>
                        <label htmlFor="name">Name </label> <br/>
                        <input type="text" name="name" id="name" placeholder=" " onChange={this.handleChange} value={this.state.name} required/>
                    </p>
                    <p>
                        <label htmlFor="email">Email </label> <br/>
                        <input type="email" name="email" id="email" placeholder=" " onChange={this.handleChange} value={this.state.email} required/>
                    </p>
                    <p>
                        <label htmlFor="subjectTitle">Subject Title </label>  <br/>
                        <input type="text" name="subjectTitle" id="subjectTitle" placeholder=" " onChange={this.handleChange} value={this.state.subjectTitle} required/>
                    </p>
                    <hr />
                    <label htmlFor="message">Message </label>  <br/>
                    <textarea type="text" name="message" id="message" onChange={this.handleChange} value={this.state.message} required />
                    {/* <input type="text" id="message" onChange={this.handleChange} value={this.state.message} required /> */}
                    <br/>
                    <button>Send<FiSend /></button>
                    {/*Netlify Integration*/}
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact"></input>                    
                </form>

            </Layout>
        )
    }
}

export default ContactPage
